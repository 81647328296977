<template>
    <div class="pr-18px w-full overflow-x-auto">
        <div class="ws-action-box gap-6 ws-select-dropdown flex items-center justify-between mt-9">
            <div class="flex filter_wrapper">
                <div class="flex w-462px">
                    <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                        <input type="text"
                            class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                            :placeholder="searchField.placeholder" v-model="searchField.text"
                            @click="searchField.placeholder = ''" v-click-outside="outsideSearchInput" />

                        <div
                            class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                            <img class="w-3 h-3" src="/images/icons/search_icon.svg" />
                        </div>
                    </div>
                </div>
                <div class="i-ml-36">
                    <button
                        class="w-212px font-poppins px-8 hover:text-white hover:bg-primary-four fs-14 bg-transparent i-border-1 border-secondary-two rounded px-1 ws-select-dropdown text-secondary-one">
                        Template Library
                    </button>
                </div>
                <div class="i-ml-36">
                    <button
                        class="w-212px font-poppins px-8 hover:text-white hover:bg-primary-four fs-14 bg-transparent i-border-1 border-secondary-two rounded px-1 ws-select-dropdown text-secondary-one">
                        My Collection
                    </button>
                </div>
            </div>
            <div class="flex relative">
                <img src="/images/icons/message_icon.svg" alt="icon" class="cursor-pointer">
                <img @click="notification = !notification" src="/images/icons/notification_icon.svg" alt="icon"
                    class="cursor-pointer i-ml-36">
                <div class="absolute dotted-notification bg-primary-five" v-if="unreadNotification.length"></div>
                <popup v-if="notification" />
            </div>
        </div>

        <div class="flex gap-9 mt-8">
            <div class="w-214px flex flex-col gap-30px flex-shrink-0 items-start">
                <div
                    class="navItem cursor-pointer px-4 py-3 pr-2 border border-primary-four hover:border-white rounded bg-white hover:bg-primary-four text-primary-one hover:text-white w-full">
                    <span class="fs-14 fw-600">Ideation & Innovation</span>
                </div>
                <div
                    class="navItem cursor-pointer px-4 py-3 pr-2 border border-primary-four hover:border-white rounded bg-white hover:bg-primary-four text-primary-one hover:text-white w-full">
                    <span class="fs-14 fw-600">Business Excellence</span>
                </div>
                <div
                    class="navItem cursor-pointer px-4 py-3 pr-2 border border-primary-four hover:border-white rounded bg-white hover:bg-primary-four text-primary-one hover:text-white w-full">
                    <span class="fs-14 fw-600">Business Excellence</span>
                </div>
                <div
                    class="navItem cursor-pointer px-4 py-3 pr-2 border border-primary-four hover:border-white rounded bg-white hover:bg-primary-four text-primary-one hover:text-white w-full">
                    <span class="fs-14 fw-600">Customer Experience</span>
                </div>
                <div
                    class="navItem cursor-pointer px-4 py-3 pr-2 border border-primary-four hover:border-white rounded bg-white hover:bg-primary-four text-primary-one hover:text-white w-full">
                    <span class="fs-14 fw-600">Business Management</span>
                </div>
            </div>

            <div class="default_templates_wrapper w-full self-start grid grid-cols-3 gap-30px">
                <Card v-for="(item, index) in templates" :key="index" @openModal="openModal" :id='item.id'
                    :bg="item.file && item.file.file_path ? item.file.file_path : defaultImg" :title="item.name"
                    :settings="{download: 0, love: 0}" :project="item" :showModal="openModal" />
                <!--              :subtitle="item.category"-->
            </div>


            <Modal v-model="showModal">
                <ModalContent :closeModal="closeModal" :createProject="createProject" :project="activeProject" />
            </Modal>

        </div>
    </div>
</template>

<script setup>
    import store from '@/store'
    import SearchBox from '@/components/workspace/SearchBox';
    import Modal from "@/elements/atom/Modal";
    import Card from '@/components/workspace/defaultTemplates/Card'
    import ModalContent from '@/components/workspace/defaultTemplates/ModalContent'
    import Popup from "@/components/notification/Popup";
    import {ref, computed, onMounted, defineEmits} from "vue";

    onMounted(async () => {
      await getTemplates();
    });

    const emit = defineEmits(['openCreateModal'])
    const searchField = ref({
      placeholder: 'Search',
      text: ''
    })
    const notification = ref(false)
    const showModal    = ref(false)
    const activeProject = ref(null)
    const defaultImg = ref("https://images.unsplash.com/photo-1659990234229-21bd3c69ba06?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60")

    const projects = computed(() => {
      return store.getters['workspace/workspaceSettingsInfo']
    })

    const notifications = computed(() => {
      return store.getters['notification/notifications']
    })

    const templates = computed(() => {
      let temps = store.getters['workspace/templates']
      if(searchField.value.text) {
        return temps.filter((item) => {
          return searchField.value.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.name.toLowerCase().includes(v));
        });
      }
      return temps;
    });

    const unreadNotification = computed(() => {
      if(!notifications.value) return []
      let unread = notifications.value.filter(item => !item.is_read)
      return unread && unread.length > 0
    })

    const closeModal = () => showModal.value = false
    const openModal  = (project) =>
    {
        activeProject.value = project;
        showModal.value = true
    }

    const createProject  = (project) =>
    {
      closeModal();
      emit("openCreateModal", project);
    }

    function getTemplates() {
      store.dispatch("workspace/getWorkspaceData", {
        type: 2,
        workspace_id: 0
      });
    }

    function outsideSearchInput() {
      searchField.value.placeholder = 'Search';
    }

</script>

<style scoped>
.navItem:is(.active, :hover) {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

/* @media all and (max-width: 1850px){
        .ws-action-box{
            height: auto;
        }
        .filter_wrapper{
            flex-wrap: wrap;
            gap: 15px;
        }
        .filter_wrapper>div{
            margin-left: 0;
        }
        .default_templates_wrapper{
            width: 100%;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }
        .default_templates_wrapper>div{
            width: 100%;
        }
    } */
</style>

<style>
/* @media all and (max-width: 1850px){
        .i-right-panel{
            overflow-y: auto;
        }
    } */
</style>
