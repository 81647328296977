<template>
    <div class="bg-white relative" style="width: 1440px;height:919px;padding: 100px 120px;">
        <button @click="closeModal" class="modalCloseBtn cursor-pointer circle absolute -right-2 -top-2 transform translate-x-1/2 -translate-y-1/2">
            <Close class="w-3 h-3 absolute left-12 bottom-12" />
        </button>
        <div class="flex justify-between flex-10" title="A lot more coming soon">
            <div style="width: 440px;">
                <h2 class="fs-30 fw-700 text-primary-one mb-2">Build your Own Ecosystem</h2>
                <h2 class="fs-20 fw-600 text-color-616161 mb-6">{{ project.name }}</h2>
                <div class="flex gap-10 text-color-616161 mb-8">
                    <div class="flex gap-1 items-center">
                        <DownloadBarIcon class="w-6 h-6" />
                        <span class="fs-18 fw-500 text-color-616161">0</span>
                    </div>
                    <div class="flex gap-2 items-center">
                        <IconHeart style="transform: scale(1.3)" />
                        <span class="fs-18 fw-500 text-color-616161">0</span>
                    </div>
                </div>
                <div class="flex gap-5 mb-8">
                    <button class="bg-primary-four shadow rounded py-2 px-6 fw-600 fs-14 text-white" @click="createProject(project)">Create Project</button>
<!--                    <button class="border border-secondary-one shadow rounded py-2 px-6 fw-600 fs-14 text-secondary-one">Add to my templates</button>-->
                </div>
                <h2 class="fs-14 fw-700 text-primary-one mb-2">About This Template</h2>
                <TextareaAutoHeight
                    class="fs-14 fw-400 text-color-616161 mb-2"
                    v-model="project.description"
                    @onType="updateProject()"
                />
            </div>
            <div class="video rounded" style="width: 660px; height: 370px;"><Video /></div>
        </div>
    </div>
</template>

<script setup>
    import TextareaAutoHeight from '@/elements/inputs/TextareaAutoHeight'
    import IconHeart from '@/components/workspace/icons/IconHeart.vue'
    import Video from '@/elements/media/Video.vue'
    import store from '@/store';

    const props = defineProps({
        closeModal: {
            type: Function
        },
        createProject: {
            type: Function
        },
        project: {
            type: Object
        }
    })

    function updateProject() {
      store.dispatch("workspace/updateProjectData", {
        id: props.project.id,
        name: props.project.name,
        description: props.project.description,
        workspace_id: props.project.workspace_id
      });
    }
</script>

<style scoped>
    .video{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
        border: 1px solid #C9C9C9;
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
    .modalCloseBtn{
        width: 200px;
        height: 200px;
        background: #0000;
    }
    .modalCloseBtn:hover{
        background: #E2E2E2;
    }
</style>
