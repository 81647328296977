import { render, staticRenderFns } from "./DefaultTemplates.vue?vue&type=template&id=573f23fa&scoped=true&"
import script from "./DefaultTemplates.vue?vue&type=script&setup=true&lang=js&"
export * from "./DefaultTemplates.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./DefaultTemplates.vue?vue&type=style&index=0&id=573f23fa&prod&scoped=true&lang=css&"
import style1 from "./DefaultTemplates.vue?vue&type=style&index=1&id=573f23fa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573f23fa",
  null
  
)

export default component.exports