<template>
    <div class="wrapper active shadow rounded overflow-hidden" :class="width">

        <div class="relative body h-135px" :style="{background: `url(${bg_img})`}">
            <label class="absolute top-3 right-3 cursor-pointer z-10">
                <MenuAltSolid class="w-5 h-5 text-white" />
                <input type="file" hidden @input="uploadImage" />
            </label>
            <div v-if="hasSeeMore" class="overlay absolute top-0 left-0 w-full h-full grid place-content-center">
                <button @click="emit('openModal', project)" class="bg-primary-four shadow rounded py-2 px-6 text-white">See More</button>
            </div>
        </div>

        <div class="footer p-3 py-3 bg-primary-three">
            <h2 class="fs-16 fw-700 text-primary-one mb-1">{{ title }}</h2>
            <p v-show="description != ''" class="fs-8 fw-400 text-secondary-one mb-4">{{ description }}</p>
            <div class="flex justify-end items-center">
<!--                <h2 v-show="subtitle != ''" class="fs-14 fw-500 text-color-616161">{{ subtitle }}</h2>-->
                <div class="flex gap-4">
                    <div class="flex gap-1 items-center">
                        <DownloadBarIcon class="w-4 h-4" />
                        <span class="fs-13 fw-500 text-color-616161">{{ settings.download }}</span>
                    </div>
                    <div class="flex gap-1 items-center">
                        <IconHeart class="w-4 h-4" />
                        <span class="fs-13 fw-500 text-color-616161">{{ settings.love }}</span>
                    </div>
                    <div class="flex gap-1 items-center cursor-pointer" @click="deleteTemplate()" :class="{'pointer-events-none': !canDelete(project.cans)}">
                      <IconTrash size="22px" style="transform: scale(0.7)" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import usePermission from '@/composable/usePermission.js'
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
    import IconHeart from '@/components/workspace/icons/IconHeart.vue'
    import IconTrash from '@/components/teamwork/sketch/icons/IconTrash.vue'
    import {ref} from 'vue'
    import store from '@/store';

    // eslint-disable-next-line no-undef
    const emit = defineEmits(["openModal"])

    // eslint-disable-next-line no-undef
    const props = defineProps({
        id: {
            type: Number
        },
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: 'w-377px'
        },
        bg: {
            type: String,
            default: '#ccc'
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    download: 0,
                    love: 0
                }
            }
        },
        hasSeeMore: {
            type: Boolean,
            default: true
        },
        project: {
          type: Object
        }
    })

    const bg_img = ref(props.bg)

    const uploadImage = (e) =>
    {
        let reader = new FileReader()
        reader.onload = (e) => {
            bg_img.value = e.target.result
        }
        reader.readAsDataURL(e.target.files[0])
    }

    function deleteTemplate() {
      if(confirm('Do you want to delete this template?')) {
        store.dispatch("workspace/deleteProject", props.id).then(response => {
          if(response) {
            store.dispatch("workspace/getWorkspaceData", {
              type: 2,
              workspace_id: 0
            });
          } else {
            alert('Something went wrong!!!');
          }
        })
      }
    }
</script>

<style scoped>
    .shadow{
        box-shadow: 0px 1.85558px 7.42233px rgba(44, 52, 65, 0.1);
    }
    .wrapper:is(:hover, .active){
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.12);
    }
    .body{
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        border-radius: 4px 4px 0 0;
    }
    .overlay{
        visibility: hidden;
        opacity: 0;
        background: rgba(255, 255, 255, 0.8);
        transition: 0.3s ease;
    }
    .body:hover .overlay{
        visibility: visible;
        opacity: 1;
    }
    .footer{
        border-radius: 0 0 4px 4px;
    }
</style>
